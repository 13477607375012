import { Box, Button, LinearProgress, Link, Stack, Typography } from "@mui/material";
import { useContract, useWaitForTransaction } from "wagmi";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect } from "react";
import { spacePuffinsContract } from "./spacePuffins";

interface Props {
  hash: string;
  refetch: () => void;
}

export const TransactionStatus = (props: Props) => {
  const contract = useContract(spacePuffinsContract);

  const { data, isSuccess } = useWaitForTransaction({
    hash: props.hash,
  });

  useEffect(() => {
    if (isSuccess) {
      props.refetch();
    }
  }, [isSuccess, props]);

  const success = () => {
    if (isSuccess && data?.status === 1) {
      return (
        <Stack direction="row" alignItems="center">
          <CheckCircleIcon color="success" fontSize="large" sx={{ mr: 1 }} />
          <Typography variant="h5">Success!</Typography>
        </Stack>
      );
    } else if (isSuccess && data?.status === 0) {
      return (
        <Stack direction="row" alignItems="center">
          <ErrorIcon color="error" fontSize="large" sx={{ mr: 1 }} />
          <Typography variant="h5">
            Transaction Failed. See <Link
          href={`${process.env.REACT_APP_ETHERSCAN_URL}/tx/${props.hash}`}
          target="_blank"
        >
          etherscan
        </Link> for details.
          </Typography>
        </Stack>
      );
    } else {
      return (
        <Box sx={{ width: "50%", mt: 2 }}>
          <LinearProgress />
        </Box>
      );
    }
  };

  const openSeaUrl = (id: string) => {
    return `https://opensea.io/assets/ethereum/${contract.address}/${id}`;  
  }

  const tweetUrl = (id: string) => {
    const params = new URLSearchParams({ text: `I just minted Space Puffin #${id} by @space_puffins`,
    hashtags: "SpacePuffins,NFT",
    url: openSeaUrl(id) });
    return "https://twitter.com/intent/tweet?" + params.toString();  
  }

  const logs = () => {
    if (data?.status === 1) {
      const logs = data.logs.map((log) => {
        const id = contract.interface.parseLog(log).args[2].toString();
        return (
          <Typography key={id}>
            #{id}
            <Button
              href={openSeaUrl(id)}
              target="_blank"
            >
              View on OpenSea
            </Button>
            <Button
              href={tweetUrl(id)}
              target="_blank"
            >
              Share on Twitter
            </Button>
          </Typography>
        );
      });

      return <>{logs}</>;
    }
  };

  return (
    <Stack direction="column" spacing={2} alignItems="center">
      <Typography variant="h3">Pending Transaction</Typography>
      <Typography>
        <Link
          href={`${process.env.REACT_APP_ETHERSCAN_URL}/tx/${props.hash}`}
          target="_blank"
        >
          {props.hash}
        </Link>
      </Typography>
      {success()}
      {logs()}
    </Stack>
  );
};
