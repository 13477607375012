import { erc721ABI } from "wagmi";

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS as string;

export const SpacePuffinsABI = [
  ...erc721ABI,
  "function allowListMint(uint256 quantity, bytes32[] calldata proof) payable",
  "function phase() view returns (uint8)",
  "function mint(uint256) payable",
  "function totalMinted() external view returns (uint256, uint256, uint256, uint256)",
  "function remainingAllowListMints(address address_) public view returns (uint256)",
  "function maxAllowListMintsPerAddress() public pure returns (uint256)"
];

export const spacePuffinsContract = {
  addressOrName: CONTRACT_ADDRESS,
  contractInterface: SpacePuffinsABI,
};

export enum Phase {
  Premint,
  AllowListMint,
  PublicMint,
  SoldOut,
}

export interface ContractState {
  address?: string;
  phase?: Phase;
  totalMinted?: number;
  maxSupply?: number;
  totalFreeMinted?: number;
  maxFreeMintSupply?: number;
}

export interface WalletState {
  address?: string;
  balanceOf?: number;
  remainingAllowListMints?: number;
  maxAllowListMintsPerAddress?: number;
}