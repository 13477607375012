import { Alert, Button, Stack, Typography } from "@mui/material";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import {
  ContractState,
  spacePuffinsContract,
  WalletState,
} from "./spacePuffins";
import { QuantityInput } from "../../components/QuantityInput";

const calculateTotal = (quantity: number) => {
  return ethers.utils.parseEther(".007").mul(quantity);
};

interface Props {
  contractState: ContractState;
  walletState: WalletState;
  setTransaction: (hash: string) => void;
}

export const PublicMint = (props: Props) => {
  const [quantity, setQuantity] = useState(5);
  const total = calculateTotal(quantity);

  const [minQuantity] = useState(1);
  const [maxQuantity, setMaxQuantity] = useState(5);

  useEffect(() => {
    const maxQuantity = 100 - (props.walletState.balanceOf || 0);
    setMaxQuantity(maxQuantity);
    setQuantity(maxQuantity < 5 ? maxQuantity : 5);
  }, [props.walletState]);

  const { config } = usePrepareContractWrite({
    ...spacePuffinsContract,
    functionName: "mint",
    overrides: {
      gasLimit: 95000,
      value: total,
    },
    args: [quantity],
  });

  const contractWriter = useContractWrite(config);
  //{ data: transaction, error, isIdle, isLoading, isSuccess, isError, write: mint }

  const handleMint = (event: React.MouseEvent<HTMLElement>) => {
    contractWriter.write?.();
  };

  const handleDecrement = () => {
    let newQuantity = quantity - 1;
    newQuantity = newQuantity < minQuantity ? minQuantity : newQuantity;
    setQuantity(newQuantity);
  };

  const handleIncrement = () => {
    let newQuantity = quantity + 1;
    newQuantity = newQuantity > maxQuantity ? maxQuantity : newQuantity;
    setQuantity(newQuantity);
  };

  const alerts = () => {
    if (contractWriter.isLoading) {
      return <Alert severity="info">Please confirm transaction.</Alert>;
    }

    if (contractWriter.isError) {
      return <Alert severity="error">{contractWriter?.error?.message}</Alert>;
    }

    if (contractWriter.isSuccess) {
      props.setTransaction(contractWriter.data?.hash || "");
      return (
        <Alert severity="success">
          Success. Transaction: {contractWriter.data?.hash}
        </Alert>
      );
    }
  };

  if (Number(props.walletState.balanceOf) >= 100) {
    return (
      <Typography variant="h3">You have reached the max mint limit.</Typography>
    );
  }

  return (
    <>
      {alerts()}
      <Typography variant="h3">Public Mint</Typography>
      <Typography>Space Puffins are .007 {ethers.constants.EtherSymbol} each.</Typography>
      <Stack direction="column" spacing={2} alignItems="center" sx={{ mt: 2 }}>
        <QuantityInput
          onDecrement={handleDecrement}
          onIncrement={handleIncrement}
          quantity={quantity}
        />
        <Button
          variant="contained"
          size="large"
          onClick={handleMint}
          disabled={contractWriter.isLoading}
        >
          Mint for {ethers.utils.formatEther(total)}{" "}
          {ethers.constants.EtherSymbol}
        </Button>
        <Button onClick={() => setQuantity(maxQuantity)}>Max</Button>
      </Stack>
    </>
  );
};

//
