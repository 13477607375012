import { Grid, Paper } from "@mui/material";
import { BigNumber } from "ethers";
import { useMemo, useState } from "react";
import { useAccount, useContractReads } from "wagmi";
import { AllowListMint } from "./AllowListMint";
import { ConnectWallet } from "./ConnectWallet";
import { ContractStatus } from "./ContractStatus";
import { PreMint } from "./PreMint";
import { PublicMint } from "./PublicMint";
import { SoldOut } from "./SoldOut";
import {
  ContractState,
  Phase,
  spacePuffinsContract,
  WalletState,
} from "./spacePuffins";
import { TransactionStatus } from "./TransactionStatus";
import { WalletStatus } from "./WalletStatus";

interface Props {}

export const Account = (props: Props) => {
  const { address } = useAccount();
  const [transxHash, setTransxHash] = useState("");

  const { data: addressData, refetch: addressDataRefetch } = useContractReads({
    contracts: [
      {
        ...spacePuffinsContract,
        functionName: "balanceOf",
        args: [address],
      },
      {
        ...spacePuffinsContract,
        functionName: "remainingAllowListMints",
        args: [address],
      },
      {
        ...spacePuffinsContract,
        functionName: "maxAllowListMintsPerAddress",
      },
    ],
    enabled: !!address,
  });

  const walletState: WalletState = useMemo(() => {
    if (addressData) {
      const [balanceOf, remainingAllowListMints, maxAllowListMintsPerAddress] =
        addressData as unknown as [BigNumber, BigNumber, BigNumber];
      return {
        address: address,
        balanceOf: balanceOf.toNumber(),
        remainingAllowListMints: remainingAllowListMints.toNumber(),
        maxAllowListMintsPerAddress: maxAllowListMintsPerAddress.toNumber(),
      };
    } else {
      return {};
    }
  }, [address, addressData]);

  const { data: contractData, refetch: contractDataRefetch } = useContractReads(
    {
      contracts: [
        {
          ...spacePuffinsContract,
          functionName: "phase",
        },
        {
          ...spacePuffinsContract,
          functionName: "totalMinted",
        },
      ],
    }
  );

  const contractState: ContractState = useMemo(() => {
    if (contractData) {
      const [phase, totalMinted] = contractData as [number, BigNumber[]];
      return {
        address: spacePuffinsContract.addressOrName,
        phase: phase,
        totalMinted: totalMinted[0].toNumber(),
        maxSupply: totalMinted[1].toNumber(),
        totalFreeMinted: totalMinted[2].toNumber(),
        maxFreeMintSupply: totalMinted[3].toNumber(),
      };
    } else {
      return {};
    }
  }, [contractData]);

  const phaseComponent = (phase: Phase) => {

    if (transxHash) {
      return (
        <TransactionStatus
          hash={transxHash}
          refetch={() => {
            addressDataRefetch();
            contractDataRefetch();
          }}
        />
      );
    }

    if (phase === Phase.SoldOut) {
      return <SoldOut />;
    }

    if (!address) {
      return <ConnectWallet />;
    }

    switch (phase) {
      case Phase.Premint:
        return <PreMint />;
      case Phase.AllowListMint:
        return (
          <AllowListMint
            contractState={contractState}
            walletState={walletState}
            setTransaction={(hash) => setTransxHash(hash)}
          />
        );
      case Phase.PublicMint:
        return (
          <PublicMint
            contractState={contractState}
            walletState={walletState}
            setTransaction={(hash) => setTransxHash(hash)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Paper elevation={2} sx={{ mb: { xs: 2, md: 4 }, p: 4, textAlign: "center" }}>
        {phaseComponent(contractState.phase || Phase.Premint)}
      </Paper>

      <Paper elevation={2} sx={{ p: 4, textAlign: "center" }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <ContractStatus contractState={contractState} />
          </Grid>
          <Grid item xs={12} md={6}>
            <WalletStatus walletState={walletState} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
