import { ImageList, ImageListItem } from "@mui/material";

interface Props {}

export const Previews = (props: Props) => {
  return (
    <ImageList cols={3} gap={32}>
      <ImageListItem>
        <img
          src="19.png"
          loading="lazy"
          alt="Space Puffin Preview"
          style={{ borderRadius: "25px" }}
        />
      </ImageListItem>
      <ImageListItem>
        <img
          src="3.png"
          loading="lazy"
          alt="Space Puffin Preview"
          style={{ borderRadius: "25px" }}
        />
      </ImageListItem>
      <ImageListItem>
        <img
          src="30.png"
          loading="lazy"
          alt="Space Puffin Preview"
          style={{ borderRadius: "25px" }}
        />
      </ImageListItem>
    </ImageList>
  );
};
