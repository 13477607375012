import { AppBar, Box, Button, IconButton, Toolbar } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { OpenSeaIcon } from "./OpenSeaIcon";
import { EtherScanIcon } from "./EtherScanIcon";
import { spacePuffinsContract } from "../features/minting/spacePuffins";
import { Link as RouterLink } from "react-router-dom";

interface Props {}

export const Header = (props: Props) => {
  return (
    <Box component="header">
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ flexGrow: 1, flexWrap: "none" }}>
            <IconButton
              size="large"
              color="inherit"
              href="https://twitter.com/space_puffins"
              target="_blank"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              size="large"
              color="inherit"
              href="https://opensea.io/collection/space-puffins"
              target="_blank"
            >
              <OpenSeaIcon />
            </IconButton>
            <IconButton
              size="large"
              color="inherit"
              href={`${process.env.REACT_APP_ETHERSCAN_URL}/address/${spacePuffinsContract.addressOrName}`}
              target="_blank"
            >
              <EtherScanIcon />
            </IconButton>
            <Button to="/rarity" component={RouterLink} variant="text">Rarity</Button>
          </Box>
          <ConnectButton />
        </Toolbar>
      </AppBar>
    </Box>
  );
};
