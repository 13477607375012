import {
  Alert,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useAccount, useContractWrite, usePrepareContractWrite } from "wagmi";
import {
  ContractState,
  spacePuffinsContract,
  WalletState,
} from "./spacePuffins";
import { QuantityInput } from "../../components/QuantityInput";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const calculateTotal = (freeEligible: boolean, quantity: number) => {
  const q = freeEligible ? quantity - 1 : quantity;
  return ethers.utils.parseEther(".007").mul(q);
};

interface Props {
  contractState: ContractState;
  walletState: WalletState;
  setTransaction: (hash: string) => void;
}

export const AllowListMint = (props: Props) => {
  const { address } = useAccount();
  const [proof, setProof] = useState<string[]>([]);
  const [verified, setVerified] = useState(false);

  const [freeEligible, setFreeEligible] = useState(true);
  const [minQuantity] = useState(1);
  const [maxQuantity, setMaxQuantity] = useState(5);
  const [quantity, setQuantity] = useState(maxQuantity);
  const total = calculateTotal(freeEligible, quantity);

  useEffect(() => {
    if (address) {
      fetch(
        process.env.REACT_APP_VERIFY_URL +
          "?" +
          new URLSearchParams({ address })
      )
        .then((response) => response.json())
        .then((data) => {
          setProof(data.proof);
          setVerified(data.proof.length > 0);
        });
    }
  }, [address]);

  useEffect(() => {
    const maxQuantity = props.walletState.remainingAllowListMints || 5;
    setMaxQuantity(maxQuantity);
    setQuantity(maxQuantity);  
    setFreeEligible(props.walletState.balanceOf === 0);
  }, [props.walletState]);

  const { config } = usePrepareContractWrite({
    ...spacePuffinsContract,
    functionName: "allowListMint",
    overrides: {
      gasLimit: 120000,
      value: total,
    },
    args: [quantity, proof],
  });

  const contractWriter = useContractWrite(config);

  if (contractWriter.isSuccess) {
    props.setTransaction(contractWriter.data?.hash || "");
    return null;
  }

  const handleMint = (event: React.MouseEvent<HTMLElement>) => {
    contractWriter.write?.();
  };

  const handleDecrement = () => {
    let newQuantity = quantity - 1;
    newQuantity = newQuantity < minQuantity ? minQuantity : newQuantity;
    setQuantity(newQuantity);
  };

  const handleIncrement = () => {
    let newQuantity = quantity + 1;
    newQuantity = newQuantity > maxQuantity ? maxQuantity : newQuantity;
    setQuantity(newQuantity);
  };

  const verificationCheck = () => {
    if (verified) {
      return (
        <Stack direction="row" alignItems="center">
          <CheckCircleIcon color="success" fontSize="medium" sx={{ mr: 1 }} />
          <Typography>{address}</Typography>
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" alignItems="center">
          <ErrorIcon color="error" fontSize="medium" sx={{ mr: 1 }} />
          <Typography>{address} not on Allow List</Typography>
        </Stack>
      );
    }
  };

  const alerts = () => {
    if (contractWriter.isLoading) {
      return <Alert severity="info">Please confirm transaction.</Alert>;
    }

    if (contractWriter.isError) {
      return <Alert severity="error">{contractWriter?.error?.message}</Alert>;
    }
  };

  const pricingDetails = () => {
    if (props.walletState.remainingAllowListMints === props.walletState.maxAllowListMintsPerAddress) {
      return (
        <Typography>
          One free! .007 {ethers.constants.EtherSymbol}/each additional{" "}
        </Typography>
      );
    } else {
      return (
        <Typography>
          You can mint {props.walletState.remainingAllowListMints} Space Puffins for .007 {ethers.constants.EtherSymbol}/each.
        </Typography>
      );
    }
  };

  const mintingForm = () => {
    if (verified) {
      return (
        <>
          {pricingDetails()}
          <QuantityInput
            onDecrement={handleDecrement}
            onIncrement={handleIncrement}
            quantity={quantity}
          />
          <Button
            variant="contained"
            size="large"
            onClick={handleMint}
            disabled={contractWriter.isLoading}
          >
            Mint for {ethers.utils.formatEther(total)}{" "}
            {ethers.constants.EtherSymbol}
          </Button>
        </>
      );
    }
  };

  if (Number(props.walletState.remainingAllowListMints) < 1) {
    return (
      <Typography variant="h5">You have reached the max Allow List mint limit.</Typography>
    );
  }

  return (
    <>
      {alerts()}
      <Stack direction="column" spacing={2} alignItems="center">
        <Typography variant="h3">Allow List Mint</Typography>
        {verificationCheck()}
        {mintingForm()}
      </Stack>
    </>
  );
};

//
