import { Box } from "@mui/material";
import { Description } from "./components/Description";
import { Facts } from "./components/Facts";
import { Previews } from "./components/Previews";
import { Account } from "./features/minting/Account";

interface Props {}

export const Home = (props: Props) => {
  return (
    <>
      <Box sx={{ mb: { xs: 2, md: 4 } }}>
        <Account />
      </Box>
      {/* <Box sx={{ my: { xs: 2, md: 6 } }}>
          <AllowListCheck />
        </Box> */}
      <Box sx={{ mb: { xs: 2, md: 4 } }}>
        <img src="spacepuffins.svg" alt="Space Puffins Logo" />
      </Box>
      <Box sx={{ mb: { xs: 2, md: 4 } }}>
        <Previews />
      </Box>
      <Box sx={{ mb: { xs: 2, md: 4 } }}>
        <Description />
      </Box>
      <Box sx={{ mb: { xs: 2, md: 4 } }}>
        <Facts />
      </Box>
    </>
  );
};
