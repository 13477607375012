import { Link, Typography } from "@mui/material";
import { WalletState } from "./spacePuffins";

interface Props {
  walletState: WalletState;
}

export const WalletStatus = (props: Props) => {
  if (!props.walletState.address) {
    return (null);
  }

  return (
    <>
      <Typography variant="h5" align="left">
        Wallet
      </Typography>
      <Typography noWrap={true} align="left">
        Address: <Link
          href={`${process.env.REACT_APP_ETHERSCAN_URL}/address/${props.walletState.address}`}
          target="_blank"
        >{props.walletState.address}</Link>
      </Typography>
      <Typography align="left">
        Puffin Balance: {props.walletState.balanceOf}
      </Typography>
    </>
  );
};
