import { Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircleSharp";

interface Props {
  quantity: number;
  onIncrement: () => void;
  onDecrement: () => void;
}

export const QuantityInput = (props: Props) => {
  return (
    <Stack direction="row" spacing={2}>
      <IconButton onClick={props.onDecrement}>
        <RemoveCircleIcon fontSize="large" />
      </IconButton>
      <Typography variant="h2">{props.quantity}</Typography>
      <IconButton onClick={props.onIncrement}>
        <AddCircleIcon fontSize="large" />
      </IconButton>
    </Stack>
  );
};
