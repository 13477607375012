import { Link, Typography } from "@mui/material";
import { ContractState, Phase } from "./spacePuffins";

interface Props {
  contractState: ContractState;
}

export const ContractStatus = (props: Props) => {
  return (
    <>
      <Typography variant="h5" align="left">
        Contract
      </Typography>
      <Typography noWrap={true} align="left">
        Address:{" "}
        <Link
          href={`${process.env.REACT_APP_ETHERSCAN_URL}/address/${props.contractState.address}`}
          target="_blank"
        >
          {props.contractState.address}
        </Link>
      </Typography>
      <Typography align="left">
        Minted: {props.contractState.totalMinted} /{" "}
        {props.contractState.maxSupply}
      </Typography>
      {/* <Typography align="left">
        Free Minted: {props.contractState.totalFreeMinted} /{" "}
        {props.contractState.maxFreeMintSupply}
      </Typography> */}
      <Typography align="left">
        Phase: {Phase[Number(props.contractState.phase)]}
      </Typography>
    </>
  );
};
