import { Link, Typography } from "@mui/material";

interface Props {}

export const Description = (props: Props) => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Space Puffins are an NFT art collection of 5000 unique birds for fans
        from every galaxy. Traveling from far far away to visit friends on the
        moon and join their new companions on earth. They have a variety of
        traits for starting rebellions and making friends.
      </Typography>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Follow us on Twitter{" "}
        <Link href="https://twitter.com/space_puffins">@space_puffins </Link>
        for project details and sneak peaks.
      </Typography>
    </>
  );
};
