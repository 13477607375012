import { Box, Typography } from "@mui/material";

interface Props {}

export default function Footer(props: Props) {
  return (
    <Box component="footer" sx={{ pb: 4 }}>
      <Typography variant="body1">
        ©2022 Space Puffins. All Rights Reserved.
      </Typography>
    </Box>
  );
}
