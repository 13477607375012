import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {}

export const Facts = (props: Props) => {
  return (
    <Paper elevation={2} sx={{ p: 4 }}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        F.A.Q.
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Where do Space Puffins live?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            They live on the Ethereum blockchain in an ERC-721A contract.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>How much do Space Puffins cost?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>They are .007 Ξ.</Typography>
          <Typography>
            For our early allowlist birds, you can mint one free and purchase up
            to 4 more in a gas saving transaction. Sign up for our{" "}
            <Link href="https://www.premint.xyz/spacepuffins/" target="_blank">
              PREMINT raffle
            </Link>
            .
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>How many Space Puffins will be minted?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>5000</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is your revenue model?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            4900 will be sold to the public for .007 Ξ each.
          </Typography>
          <Typography>
            900 will be distributed free through PREMINT raffles.
          </Typography>
          <Typography>100 will be retained in the treasurey.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Wen utility? Wen token? Wen Game? Where Metaverse?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Space Puffins are an NFT art collection.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>When is the reveal?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>We feature Instant Reveal when minted.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>What is the contract address?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography><Link href="https://etherscan.io/address/0xcc177989cae62e3c9e0c5a2499aa4cf01641c9d2" target="_blank">0xcc177989cae62e3c9e0c5a2499aa4cf01641c9d2</Link></Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>How are the Space Puffins licensed?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Coming soon. We are researching and gathering feedback to choose the best option for the holders.</Typography>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
