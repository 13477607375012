import { Link, Typography } from "@mui/material";

interface Props {
}

export const SoldOut = (props: Props) => {

  return (
    <>
      <Typography variant="h5" align="center">
        Space Puffins are Sold Out. Find your puffin on the{" "}
        <Link href="https://opensea.io/collection/spacepuffins" target="_blank">
          OpenSea
        </Link>
        .
      </Typography>
    </>
  );
};
