import { Stack, Typography } from "@mui/material";

interface Props {}

export const PreMint = (props: Props) => {
  return (
    <Stack direction="column" spacing={2} alignItems="center">
      <Typography variant="h3">Allow List Mint Open</Typography>
      <Typography>
       Connect wallet to check if you are on the allow list.
      </Typography>
      <Typography>
       Public mint opens 8/22 4:00 PM UTC.
      </Typography>
    </Stack>
  );
};
