import { Box, Container } from "@mui/material";
import Footer from "./components/Footer";
import { Header } from "./components/Header";

interface Props {
  children: React.ReactNode;
}

export const Layout = (props: Props) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh"
      }}
    >
      <Header />
      <Box sx={{ flex: "1 0 auto", my: { xs: 2, md: 4 } }}>
        {props.children}
      </Box>
      <Footer />
    </Container>
  );
};