import { Stack, Typography } from "@mui/material";
import { ConnectButton } from "@rainbow-me/rainbowkit";

interface Props {}

export const ConnectWallet = (props: Props) => {
  return (
    <Stack direction="column" spacing={2} alignItems="center">
      <Typography variant="h3">Public Mint Open</Typography>
      <ConnectButton />
    </Stack>
  );
};
