import { Box, Paper } from "@mui/material";
import rarities from "./data/rarities.json";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const columns: GridColDef[] = [
  {
    field: "attribute",
    headerName: "Attribute",
    width: 100,
    editable: false,
  },
  {
    field: "value",
    headerName: "Value",
    width: 200,
    editable: false,
  },
  {
    field: "count",
    headerName: "Occurences",
    width: 150,
    editable: false,
  },
  {
    field: "percentage",
    headerName: "Percentage",
    width: 150,
    editable: false,
  },
];

interface Props {}

export const Rarity = (props: Props) => (
  <Paper sx={{ p: 2 }}>
    <h1>Trait Rarity</h1>
    <Box sx={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={rarities}
        getRowId={(row) => `${row.attribute}:${row.value}`}
        columns={columns}
        pageSize={77}
        rowsPerPageOptions={[5]}
      />
    </Box>
  </Paper>
);
